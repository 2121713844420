import { actions } from '../actions/loading.actions';

const INITIAL_STATE = {
  loading: false,
  message: ''
}
const reducer = (state = INITIAL_STATE, { type, data, message }) => {
  switch (type) {
    case actions.SET_LOADING:
      return { ...state, loading: data, message: message ? message : '' }
    default:
      return state
  }
}
export default reducer