import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';

import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Spin } from 'antd';
import SiderMenu from '../../components/Menu/Menu';
import { push, replace } from 'connected-react-router';
import { removeItem } from '../../utils/storage';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const AppSider = ({ collapsed, onMenuSelect, user }) => {
  return (
    <Sider
      style={{ background: '#fff' }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={220}
    >
      <div className="logo" >
        <img src={logo} alt="logo" />
        <span style={{ fontSize: 16, fontWeight: 600, letterSpacing: 0.5, marginLeft: 8 }}> BPAYS101 </span>
      </div>
      <SiderMenu onMenuSelect={onMenuSelect} user={user} />
    </Sider>
  )
}


const AppHeader = ({ collapsed, toggle, logout, props, user, dispatch }) => {

  return (
    <Header style={{ background: '#fff', padding: '0px 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span onClick={toggle}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </span>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        <p style={{ margin: 0 }}>Welcome: {user?.name}</p>
        {user?.role !== 'admin' && user?.role !== 'processor' ? <p style={{ margin: '0px 12px' }}>Available Amount: {user?.wallet?.amount}</p> : null}
        <Menu
          mode="horizontal"
        >
          <SubMenu icon={<UserOutlined style={{ paddingLeft: 8 }} />}>
            <MenuItemGroup>
              {user?.role !== 'processor' ? <Menu.Item key="setting:1" onClick={() => dispatch(replace('/dashboard/profile'))}>
                <p>
                  <UserOutlined style={{ paddingRight: 4 }} />
                  Profile
                </p>
              </Menu.Item> : null}
              <Menu.Item key="setting:2" >
                <p onClick={logout}>
                  <LogoutOutlined style={{ paddingRight: 4 }} />
                  Logout
                </p>
              </Menu.Item>
            </MenuItemGroup>
          </SubMenu>
        </Menu>
      </div>
    </Header>
  )
}

const AppFooter = () => {
  return <Footer
    style={{ textAlign: 'center', fontFamily: 'Arimo', fontSize: '12px', }}  >
    <b>Contact:</b> <a href="mailto:abc@abc.com">abc@abc.com</a> for issues.
  </Footer>
}


const App = (props) => {
  const [collapsed, setCollapsed] = useState(false)

  const user = useSelector(state => state.user.currentUser)
  const { loading, message } = useSelector(state => state.loading)


  const dispatch = useDispatch()

  const onMenuSelect = (key) => {
    dispatch(push(key));
  }
  const toggle = () => {
    setCollapsed(!collapsed)
  }
  const logout = () => {
    removeItem('user')
    dispatch(replace('/'))
  }
  return (
    <div className='reactRoot'>
      <div style={{ height: '100vh', position: 'absolute', width: '100%', zIndex: 10000000, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Spin spinning={loading} size='large' style={{ color: '#D4008E' }} />
        <h1 style={{ color: '#D4008E', marginLeft: 8, marginBottom: 2 }}>{message}</h1>
      </div>
      <Layout style={{ height: '100vh' }}>
        <AppSider collapsed={collapsed} onMenuSelect={onMenuSelect} user={user} />
        <Layout>
          <AppHeader collapsed={collapsed} toggle={toggle} logout={logout} props={props} user={user} dispatch={dispatch} />
          <Content style={{ margin: '16px 16px 0px 16px', padding: 24, background: '#fff', minHeight: 280, }}>
            {props.children}
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
