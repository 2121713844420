import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import user from './user.reducer';
import loading from './loading.reducer';


// Root Reducer.
const reducer = history => combineReducers({
  router: connectRouter(history),
  user,
  loading
});

export default reducer