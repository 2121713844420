const getEnv = () => {
  const appEnv = window.location.host.split('.')[0];
  switch (appEnv) {
    case 'mobile':
      return 'prod';
    case 'testing':
      return 'test';
    default:
      return 'local';
  }
}

const apiUrl = () => {
  switch (getEnv()) {
    case 'test':
      return 'https://alchemdex-dev-api.azurewebsites.net/api/';
    case 'prod':
      return 'https://appalchemdexstage.azurewebsites.net/api/';
    default:
      return 'https://appalchemdexstage.azurewebsites.net/api/' ;
  }
}


const api = {
  url: apiUrl(),
};

export default api