import React from 'react';
import { Menu, } from 'antd';
import PropTypes from 'prop-types';
import { DollarOutlined, PieChartOutlined, UserAddOutlined } from '@ant-design/icons';

// Sider Menu Component
const SideMenu = ({ onMenuSelect, user }) => {
  console.log(window.location.pathname)
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['1']}
      onSelect={({ key }) => { onMenuSelect(key); }}
      selectedKeys={[window.location.pathname]}
    >
      <Menu.Item key="/dashboard">
        <PieChartOutlined />
        <span>Dashboard</span>
      </Menu.Item>
      {user?.role !== 'dealer' && user?.role !== 'processor' ? <Menu.Item key="/dashboard/users">
        <UserAddOutlined />
        <span>Users</span>
      </Menu.Item> : null}
      <Menu.Item key="/dashboard/payments">
        <UserAddOutlined />
        <span>Payments</span>
      </Menu.Item>
      {user?.role !== 'processor' ? <Menu.Item key="/dashboard/payment-activities">
        <DollarOutlined />
        <span>Activities</span>
      </Menu.Item> : null}
    </Menu>
  );
}

SideMenu.propTypes = {
  onMenuSelect: PropTypes.func
}

export default SideMenu;