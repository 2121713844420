import logo from '../../assets/images/logo.png'
import ACDText from '../../assets/images/acd-text.png'

const DefaultPage = () => {


  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', padding: 24 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={logo} style={{ height: '30vh' }} />
        <img src={ACDText} style={{ height: '4vh', marginTop: 24 }} />
      </div>
    </div>


  )
}

export default DefaultPage