import { setUser } from "../redux/actions/user.actions"
import { openNotificationWithIcon } from "../utils/Notification"
import { getItem, setItem } from "../utils/storage"
import { request } from "./verb.services"

export const loginUser = (data) => {
  return dispatch => {
    return request('users/login', 'post', data, false)
      .then(({ data }) => {
        dispatch(setUser(data.data.data))
        setItem('user', data.data.data)
        setItem('tokenContainer', { token: data.data.token })
        console.log(getItem('tokenContainer'))
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const verifyUser = () => {
  return dispatch => {
    return request('users/verify', 'get', null, true)
      .then(({ data }) => {
        dispatch(setUser(data.data))
        setItem('user', data.data)
        return data.data
      })
      .catch((e) => {
        console.log(e)
        openNotificationWithIcon('error', 'Error!', e.response.data.message);
      })
  }
}

export const verifyUserEmail = (data) => {
  console.log(data)
  return request('User/ConfirmEmail', 'put', data, false)
    .then(({ data }) => {
      openNotificationWithIcon('success', 'Success!', 'Your Email has been verified successfully! ')
      return data
    })
    .catch((e) => {
      console.log(e.response)
      openNotificationWithIcon('error', 'Error!', e.response.statusText);
    })
}