import Dashboard from "../containers/Dashboard/Dashboard";



// router configuration.
let routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    exact: true
  },

]

export default routes;