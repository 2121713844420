
import './Dashboard.scss'



const Dashboard = () => {
  // const [loading, setLoading] = useState(false);
  



  return (
    <div>
      Dashboard
    </div>
  )
}

export default Dashboard