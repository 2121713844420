import { Spin } from "antd"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setLoading } from "../../redux/actions/loading.actions"
import { verifyUserEmail } from "../../services/auth.services"
import logo from '../../assets/images/logo.png'
import ACDText from '../../assets/images/acd-text.png'

const EmailVerification = () => {
  const loading = useSelector(state => state.loading.loading)
  const dispatch = useDispatch()
  useEffect(() => {
    const [a, b, token, userId] = window.location.pathname.split('/')
    console.log(token, userId)
    if (token && userId) {
      dispatch(setLoading(true))
      verifyUserEmail({ token: decodeURIComponent(token), userId })
      setTimeout(() => {
        window.location.replace('/')
      }, 2000);
      dispatch(setLoading(false))

    }
  }, [])
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', padding: 24 }}>
      <Spin spinning={loading} size="large" style={{ color: "#D4008E", }} >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={logo} style={{ height: '20vh' }} />
          <img src={ACDText} style={{ height: '3vh', marginTop: 24 }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
          <h1>Verifying....</h1>
        </div>
      </Spin>
    </div>


  )
}

export default EmailVerification